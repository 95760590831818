import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

function FAQ() {
  return (
    <div className="grid-3">
      <h2>FAQ</h2>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>What is Abandoned Arena?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Abandoned Arena is an upcoming competitive game that integrates with NFTs on the Radix DLT.<br></br>
            You will be able to use the Abandoned Scorpions NFTs you own to compete with others.
            <br></br>
            Eventually, other NFTs will be supported as well.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography>What is $ARENA?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            When playing the game, you bet a certain amount of $XRD, with a minimum bet.<br></br>
            Battles on the network will have a fee. (10%, subject to change or adjustments with price fluctuation)
            <br></br>
            The winner will receive the sum of both bets, minus the fee, e.g. for a 100 $XRD bet, 200 * 0.9 = 180 $XRD
            goes to the winner.<br></br>
            The fee goes entirely to $ARENA holders (e.g, hold 10% of supply, earn 2 $XRD from the aforementioned
            battle)
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
          <Typography>How will the game work?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The game will be an autobattler, at least for now. User interaction in real time may be more feasible at
            Xi'an release.<br></br>
            This means you will set a team and then open a challenge, and the person who accepts the challenge will
            match with your team.<br></br>
            Units (NFTs) will have in-battle abilities based on their traits, e.g. a Halo Scorpion heals a % of max HP
            after every round.<br></br>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
          <Typography>Ok can you be more specific on the game mechanics?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Primary Stats:
            <ul>
              <li>
                <b>HP</b>: How much health the unit has. If brought to zero, faints and is no longer able to attack or
                defend.
              </li>
              <li>
                <b>STR</b>: How much base damage the unit does with each attack (actual damage dealt is determined by
                other factors such as traits and the defending unit's traits)
              </li>
              <li>
                <b>DEF</b>: Reduces damage taken
              </li>
              <li>
                <b>DEX</b>: When units square off, the unit with higher DEX attacks first. DEX also influences other
                mechanics such as dodge change and hit chance.
              </li>
            </ul>
            Game Phases:
            <ul>
              <li>
                <b>Best Players</b>: The players with the highest rankings, determined by win percentage and other
                factors (exact formula TBD, it is necessary to think carefully about this to eliminate abuse/cheating,
                particularly since leaderboards will have rewards each season)
              </li>
              <li>
                <b>Combat</b>: Imagine 5 scorpions facing 5 others in a line:<br></br>
                xxxxX | Ooooo
                <br></br>
                The two units closest to each other (X and O) are <i>squaring off</i>, meaning they are attacking each
                other (taking turns, very politely)<br></br>
                The one with the highest <b>DEX</b> will attack first, and deal damage depending on its traits and the
                defender's traits. It may additionally trigger an ability on attack, if it has one. The defender may
                trigger an ability when taking damage, if it has one. Then the slower unit will attack, and this will
                repeat until one of them reaches <b>0 HP</b> and faints. The fainting unit is replaced with the next one
                on its team, and if there aren't any more, that team is declared the loser.
              </li>
              <li>
                <b>Postcombat</b>: Not really part of the game, but after the combat, the winner receives their
                winnings, the loser receives nothing, and leaderboards are updated.
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5a-content" id="panel5a-header">
          <Typography>What do you mean, leaderboards?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            There will be 3 types of leaderboards:
            <ul>
              <li>
                <b>Best Players</b>: The players with the highest rankings, determined by win percentage and other
                factors (exact formula TBD, it is necessary to think carefully about this to eliminate abuse/cheating,
                particularly since leaderboards will have rewards each season)
              </li>
              <li>
                <b>Combat</b>: Imagine 5 scorpions facing 5 others in a line, e.g. xxxxx | ooooo
              </li>
              <li>
                <b>Postcombat</b>: Not really part of the game, but after the combat, the winner receives their
                winnings, the loser receives nothing, and leaderboards are updated.
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6a-content" id="panel6a-header">
          <Typography>
            In the last section, you mentioned <i>seasons</i>, what is that?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The game leaderboards will be reset after a period of time, called a "season". The closure of each season
            will be accompanied by major game updates (balance, new content, anything that might affect the leaderboards
            and would be unfair to release mid-season) and also prizes for the Best Players, Biggest Winners, and
            honorary hall of fame decorations for Best Scorpions.<br></br>
            <br></br>
            This system allows for game balance in a fair manner, and will allow players to take their spot in history
            as e.g. <i>Season 1 Champion</i>, <i>Season 2 Second Place</i>, etc.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7a-content" id="panel7a-header">
          <Typography>So it's pay to win?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Kinda. You have to own these NFTs to play at all, but I'm making every effort possible to make the barrier
            to entry low - extremely rare NFTs will have special abilities, but not extremely overpowered ones.<br></br>
            A future feature might allow non-holders to play by enlisting other people's NFTs that they've designated as{' '}
            <i>free agents</i>, essentially hiring them for the battle in exchange for a cut of the rewards.<br></br>
            Futhermore, as more NFT collections are eventually added to the arena, the game will be more accessible.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel8a-content" id="panel8a-header">
          <Typography>Why is this site so bad?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Before this site was launched, the token URL for $ARENA was pointing to "site not found", which is a bad
            look. Rest assured, better site coming soon.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel9a-content" id="panel9a-header">
          <Typography>I have more questions.</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Please join the{' '}
            <a className="link" target="_blank" href="https://t.me/kfcgamedev" rel="noreferrer">
              telegram chat
            </a>{' '}
            and ask away!
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default FAQ
