import React from 'react'

function Links() {
  return (
    <div>
      <h2 className="links-section">Links</h2>
      <ul>
        <li>
          <a className="link" target="_blank" href="https://t.me/kfcgamedev" rel="noreferrer">
            telegram chat
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <a
            className="link"
            target="_blank"
            href="https://www.radixscan.io/token/arena_rr1qvz7p83cgpyffgy4rq9dmwntylmm2j0qvlgmca5sf3kqlu8p2p"
            rel="noreferrer"
          >
            token information
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <a className="link" target="_blank" href="https://www.scroplife.com/" rel="noreferrer">
            scroplife.com
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <a
            className="link"
            target="_blank"
            href="https://radstrike.com/scorpions/about/#instructions"
            rel="noreferrer"
          >
            trade scorpions
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <a className="link" target="_blank" href="https://caviarswap.io/p/100k" rel="noreferrer">
            trade $ARENA
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Links
